// import React from "react";
// import Details4IdHelper from "../helper/Details4IdHelper";
// import * as divstyles from '../styles/head.module.css';


// function JSONLoader() {
//   let inputId = "b9661041743b3533b813b13a90a0-4145-Xavier-St-Denver-CO";
//   let jsonObject = Details4IdHelper.getJsonData4Id(inputId);//TODO remove this to fetch values from parent component
//   return (
//     <div>
//       <div>
//       </div>
//       {
//         Object.entries(jsonObject).forEach(([key, value]) => {
//           <p>
//             <div className={divstyles.row}>
//               <div className={divstyles.column}>{key}: </div>
//               <div className={divstyles.column}> {value}</div>
//             </div>
//           </p>
//         })
//       }
//     </div>
//   )
// }
// export default JSONLoader;
// //Object.entries(fido).map(([key,value])=>{ return ( <div>{key} : {value.toString()}</div> ); })
// // {Object.entries(sampleInputJson).map(([key, i]) =>
// //     ( <li key={i}>
// //     <span>key: {key.id} Name: {i}</span>
// //      </li> ))}


import React from 'react';
import { Col,Container, Row } from 'react-bootstrap';

// import sampleInputJson from '../data/details4id-sample-json';
import * as divstyles from '../styles/head.module.css';
// let sampleInputJsonArray = {
//   "averageRETRateCounty": 7.14573456120853,
//   "city_name": "Denver",
//   "createdAt": "2021-03-09 05:26:22",
//   "dataCategory": "clientIdPropertyData",
//   "door_number": "4145",
//   "dwelling_result": 191820,
//   "dwellingcost_uid": "4145--Xavier--St--Denver--CO",
//   "eventStr": "calculatedHomeInsurance",
//   "homeInsuranceAmount": "160",
//   "id": "b9661041743b3533b813b13a90a0-4145-Xavier-St-Denver-CO",
//   "liabilityAmount": 300000,
//   "lifeInsuranceAmount": 27.5123321296046,
//   "mortgageAmount": "2582",
//   "primaryResidence": "Yes",
//   "propertyTaxAmount": "423",
//   "state_name": "CO",
//   "street": "Xavier",
//   "street_type": "St",
//   "uid": "c9661041743b3533b813b13a90a0-4145-Xavier-St-Denver-CO",
//   "updatedAt": "2021-03-09 05:26:22",
//   "zip": "80212"
// };

function JSONLoader( props ) {
//  const { data } = props;
  // data.slice(0,10);

  return (
    <div>
      <Container fluid className={divstyles.GridRow}>
        <Row className={divstyles.GridRow}>

          <Col sm={3} className={divstyles.GridRow} >KEY</Col>

          <Col sm={9} className={divstyles.GridRow}>VALUE</Col>
        </Row>

        {/* {

          data && data.forEach(([key, value]) => {
            console.log("key:", key, " # value: ", value);

            <Row className={divstyles.GridRow}>
              <Col sm={3} className={divstyles.GridRow}> {key}</Col>
              <Col sm={9} className={divstyles.GridRow}> {value}</Col>
            </Row>

          })
        } */}

      </Container>
    </div>

  );
}
export default JSONLoader;

//   Object.keys(data).map((key, i) => (
//Object.entries(fido).map(([key,value])=>{ return ( <div>{key} : {value.toString()}</div> ); })
// {Object.entries(sampleInputJson).map(([key, i]) =>
//     ( <li key={i}>
//     <span>key: {key.id} Name: {i}</span>
//      </li> ))}
