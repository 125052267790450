import React, { useEffect,useState } from 'react';

import Details4Ids from '../components/details4ids';
import JSONLoader from '../components/JSONLoader';
import * as divstyles from '../styles/container.module.css';
//import fetchHelper from '../helper/fetch-helper';;

function Details4Id() {
  const [ input, setInput ] = useState( ' ' );
  const [ uid, setUid ] = useState( '' );
  const [ isSubmitted, setIsSubmitted ] = useState( false );
  const [ data, setData ] = useState( [] );
  const handleSubmit = () => {
    setUid( input );
    setIsSubmitted( true );
    console.log( 'Submit Button clicked' );
  };
  const handleChange = ( event ) => {
    // console.log(event.target.value);
    setInput( event.target.value );
  };
    // useEffect(() => {
    //   fetchHelper.get("https://jsonplaceholder.typicode.com/posts").then(res=>(setData(res.data)))

  // }, [])

  return (
    <>
      <div className={divstyles.textbox}>
        <textarea
          className={divstyles.textbox}
          name="input"
          placeholder="Enter id"
          value={input}
          onChange={handleChange}
        ></textarea>

        <h1>User Input: {uid}</h1>

        <br />

        <button type="submit" onClick={handleSubmit}>Submit</button>

        {isSubmitted && <JSONLoader data={data} />}

        <Details4Ids />
      </div>
    </>
  );

}
export default Details4Id;
